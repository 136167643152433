import {useClassName} from "../ClassNameHook";
import * as React from "react";
import {HeroAvatar} from "../hero-avatar/HeroAvatar";
import {HomeWelcome} from "../home-welcome/HomeWelcome";
import "./Header.scss";

export const Header = () => {
    const _ = useClassName('Header');
    return (
        <div className={_()}>
            <HeroAvatar/>
            <HomeWelcome/>
        </div>
    )
};