export type ClassNameGetter = (suffix?: string) => string;

export class ClassNameHelper {
    private classNames: string[];

    constructor() {
        this.getClassName = this.getClassName.bind(this);
    }

    /**
     * A string containing one or more class names, eg: `foo` or `foo foo-primary`
     * @param name
     */
    public setClassName(name: string): void {
        this.classNames = name.trim().split(/\s+/);
    }

    /**
     * Given a string of one or more suffixes, eg
     *      `__item` or `__item __item--is-active`,
     * return a new string with all suffixes appended to the previously set class names,
     * eg:
     *      `foo__item foo-primary__item foo__item--is-active foo-primary__item--is-active`
     * @param suffix
     * @returns {string}
     */
    public getClassName(suffix: string = ""): string {
        return suffix
            .trim()
            .split(/\s+/)
            .map((s: string) => {
                return this.classNames.map((name: string) => {
                    return name + s;
                }).join(" ");
            })
            .join(" ");
    }

    /**
     * Returns a callback that when given a suffix, will return the resulting
     * class name as if the `getClassName` instance had been called
     *
     * @returns {any}
     */
    public getClassNameGetter(): (suffix?: string) => string {
        return this.getClassName;
    }
}