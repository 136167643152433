export class HeroAvatar_BlinkController {

    private currentFrame: number = 0;
    private totalFrames: number = 5;
    private spriteFrameHeight: number = 26;
    private minWaitSeconds: number = 3;
    private maxWaitSeconds: number = 8;
    private framesPerSecond: number = 20;
    private blinkTimeout: number | null = null;

    constructor(private eyesElement: HTMLElement) {
        this.blink = this.blink.bind(this);
    }

    public start(): void {
        if (this.blinkTimeout !== null) {
            return;
        }
        this.blink();
    }

    public stop(): void {
        if (this.blinkTimeout === null) {
            return;
        }
        clearTimeout(this.blinkTimeout);
    }

    public release(): void {
        this.stop();
        this.eyesElement = null;
    }

    private blink(): void {
        const backgroundY: number = (this.currentFrame * this.spriteFrameHeight) * -1;
        this.eyesElement.style.backgroundPositionX = "0";
        this.eyesElement.style.backgroundPositionY = `${backgroundY}px`;
        this.currentFrame++;
        this.scheduleNextBlink();
    }

    private scheduleNextBlink(): void {
        let nextTime: number;
        // all frames ran
        // => reset play head and run again after a random amount of time
        if (this.currentFrame === this.totalFrames) {
            const waitSeconds: number = this.minWaitSeconds + (Math.random() * (this.maxWaitSeconds - this.minWaitSeconds));
            nextTime = Math.round(waitSeconds * 1000);
            this.currentFrame = 0;
        }
        // otherwise run again at the next frame speed
        else {
            nextTime = 1000 / this.framesPerSecond;
        }
        this.blinkTimeout = setTimeout(this.blink, nextTime) as any;
    }

}