import {useClassName} from "../../ClassNameHook";
import {Header} from "../../header/Header";
import React = require("react");
import "./Page_Home.scss";

export const Page_Home = () => {
    const _ = useClassName('Page_Home');
    return (
        <div className={_()}>
            <Header/>
        </div>
    )
};