import * as React from "react";
import {useEffect, useRef} from "react";
import {HeroAvatar_BlinkController} from "./HeroAvatar_BlinkController";
import {useClassName} from "../ClassNameHook";
import "./HeroAvatar.scss";

export const HeroAvatar = () => {
    const _ = useClassName('HeroAvatar');
    const eyesRef = useRef(null);
    useEffect(() => {
        const controller = new HeroAvatar_BlinkController(eyesRef.current);
        controller.start();
        return () => {
            controller.release();
        };
    });

    return (
        <div className={_()}>
            <div className={_('__eyes')} ref={eyesRef}/>
        </div>
    )
};