import {ClassNameGetter, ClassNameHelper} from "./ClassNameHelper";
import {useState} from "react";

export function useClassName(baseClassName: string): ClassNameGetter {
    const [state, setState] = useState(() => {
        const helper = new ClassNameHelper();
        helper.setClassName(baseClassName);
        return helper.getClassNameGetter();
    });
    return state;
}