import {useClassName} from "../ClassNameHook";
import React = require("react");
import "./HomeWelcome.scss";

export const HomeWelcome = () => {
    const _ = useClassName('HomeWelcome');

    return (
        <div className={_()}>
            <h1 className={_("__title")}>
                Hello, I'm Alex
            </h1>
            <h2 className={_("__text")}>
                <ul>
                    <li>code slinger</li>
                    <li>pixel pusher</li>
                    <li>motion shaper</li>
                    <li>vertex wrangler</li>
                </ul>
            </h2>
        </div>
    )
};